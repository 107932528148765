import { getAccountList, getJuListLatest } from '../api'
import { useQuery } from '@tanstack/react-query'
import HomeInner from './Inner'

function Home() {
  const {
    isPending: JuIsPending,
    error: JuError,
    data: juData,
  } = useQuery({
    queryKey: ['ju-list'],
    queryFn: () => getJuListLatest().then(res => res.data),
  })

  const {
    isPending,
    error,
    data: accountsData,
  } = useQuery({
    queryKey: ['account-list'],
    queryFn: () => getAccountList().then(res => res.data),
  })

  if (isPending || JuIsPending) return 'Loading...'
  if (error || JuError) return 'An error has occurred: ' + error.message

  const accountList = accountsData.data.rows
  const juList = juData.data.rows

  return <HomeInner accountList={accountList} juList={juList} />
}

export default Home
