import React from 'react'
import { getAccountList } from '../../api'
import StartJuFormInner from './StartJuFormInner'
import { useQuery } from '@tanstack/react-query'

const StartJuForm = ({ onClose }) => {
  const {
    isPending,
    error,
    data: accountsData,
  } = useQuery({
    queryKey: ['account-list'],
    queryFn: () => getAccountList().then(res => res.data),
  })
  const accountList = accountsData.data.rows

  if (isPending) {
    return 'Loading...'
  }
  if (error) return 'An error has occurred: ' + error.message

  return <StartJuFormInner accountList={accountList} onClose={onClose} />
}

export default StartJuForm
