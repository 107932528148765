import React, { useMemo, useState } from 'react'
import { Input, Form, Button, Space, Row, Col, Alert, Tag } from 'antd'
import { filter, get, map, sortBy } from 'lodash-es'
import { createJu, createDans, setAccountSwapPool, swapAccountPool } from '../../api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import styles from './index.module.css'

function AccountItem({ account }) {
  return (
    <Col span={4} key={get(account, 'rowid')} className={styles['account-col']}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {get(account, '66895b3a8c68dc973221e386')}
        <Tag color="geekblue" bordered={false}>
          {get(account, '6695154ef234e4ca8040548b')}
        </Tag>
      </div>
    </Col>
  )
}

const StartJuFormInner = ({ accountList, onClose }) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const createJuMutation = useMutation({
    mutationFn: createJu,
    onSuccess: () => {
      queryClient.invalidateQueries(['ju-list'])
    },
  })

  const setAccountSwapPoolMutation = useMutation({
    mutationFn: setAccountSwapPool,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-list'])
    },
  })

  const createDansMutation = useMutation({
    mutationFn: createDans,
    onSuccess: (data, variables) => {
      const juId = get(variables, 'juId')
      queryClient.invalidateQueries(['ju-item', juId, 'dans'])
    },
  })

  const { newAccountList, intersectionPools, poolChangedAccountList, intersectionAvailables } = swapAccountPool({
    accountList,
  })

  // 筛选不是备用账号的
  const availableAccountList = useMemo(() => {
    return filter(newAccountList, accountItem => {
      return get(accountItem, '668faebf8c68dc973227f3f3') !== '1'
    })
  }, [newAccountList])

  const sortedAvailableAccountList = sortBy(
    availableAccountList,
    account => -Number(get(account, '6695154ef234e4ca8040548b'))
  )
  const disabled = 81 !== availableAccountList.length

  return (
    <Form
      onFinish={async v => {
        if (disabled) {
          return
        }

        setLoading(true)
        await setAccountSwapPoolMutation.mutateAsync(poolChangedAccountList)
        const data = await createJuMutation.mutateAsync({ name: v.name })
        const success = get(data, 'data.success')
        const juId = get(data, 'data.data')
        setLoading(false)

        if (juId && success) {
          createDansMutation.mutateAsync({
            juId: juId,
            accountList: availableAccountList,
          })
          onClose()
        }
      }}
      layout="vertical"
      initialValues={{
        name: moment().format('MM月DD日'),
      }}
      form={form}
    >
      <Form.Item name="name" label="起一个名字">
        <Input autoFocus />
      </Form.Item>

      <div>参与账号 ({sortedAvailableAccountList.length}) 个</div>

      {disabled && (
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Alert message="可用的账号数量必须是81个" type="error" showIcon />
        </div>
      )}

      <Row className={styles['account-row']}>
        {map(sortedAvailableAccountList, account => {
          return <AccountItem key={get(account, 'rowid')} account={account} />
        })}
      </Row>

      {intersectionPools.length > 0 && (
        <>
          <h5>移入</h5>
          <Row className={styles['account-row']}>
            {map(intersectionPools, account => {
              return <AccountItem key={get(account, 'rowid')} account={account} />
            })}
          </Row>
        </>
      )}

      {intersectionAvailables.length > 0 && (
        <>
          <h5>移出</h5>
          <Row className={styles['account-row']}>
            {map(intersectionAvailables, account => {
              return <AccountItem key={get(account, 'rowid')} account={account} />
            })}
          </Row>
        </>
      )}

      <Space className="actions" style={{ marginTop: 20 }}>
        <Button type="primary" htmlType="submit" disabled={disabled} loading={loading}>
          确定
        </Button>
        <Button onClick={() => onClose()}>取消</Button>
      </Space>
    </Form>
  )
}

export default StartJuFormInner
