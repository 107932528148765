import { Button, Modal, Row, Col, Tag } from 'antd'
import { useMemo, useState } from 'react'
import { map, filter, get } from 'lodash-es'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { resetAccountLianhong } from '../../api'
import JuItemInner from '../../components/JuItemInner'
import StartJuForm from '../../components/StartJuForm'

function LianAccountItem({ account, resetAccountLianhongMutation }) {
  return (
    <Col span={6} key={get(account, 'rowid')} style={{ border: '1px solid #eee', padding: 5 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          {get(account, '66895b3a8c68dc973221e386')}（{get(account, '669f43866175d4bd1c3ef988')}）
          <Tag color="#f50" style={{ fontWeight: 'bold', marginLeft: 8 }}>
            {get(account, '6695154ef234e4ca8040548b')}
          </Tag>
        </div>
        <Button
          type="link"
          onClick={() => {
            resetAccountLianhongMutation.mutateAsync({
              account,
            })
          }}
        >
          重置
        </Button>
      </div>
    </Col>
  )
}

function Inner({ accountList, juList }) {
  const queryClient = useQueryClient()
  const currentJu = juList[0]
  const [open, setOpen] = useState(false)
  const poolAccountList = filter(accountList, accountItem => {
    return get(accountItem, '668faebf8c68dc973227f3f3') === '1'
  })

  const resetAccountLianhongMutation = useMutation({
    mutationFn: resetAccountLianhong,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-list'])
    },
  })

  const fourLianAccountList = useMemo(() => {
    return filter(poolAccountList, account => {
      const lian = get(account, '6695154ef234e4ca8040548b')

      if (Number(lian) === 4) {
        return true
      }

      return false
    })
  }, [poolAccountList])

  const otherLianAccountList = useMemo(() => {
    return filter(poolAccountList, account => {
      const lian = get(account, '6695154ef234e4ca8040548b')

      if (Number(lian) !== 4) {
        return true
      }

      return false
    })
  }, [poolAccountList])

  return (
    <div>
      {fourLianAccountList.length > 0 && (
        <div style={{ padding: '10px 30px' }}>
          <h4>四连账号</h4>
          <Row>
            {map(fourLianAccountList, account => {
              return (
                <LianAccountItem
                  key={get(account, 'rowid')}
                  account={account}
                  resetAccountLianhongMutation={resetAccountLianhongMutation}
                />
              )
            })}
          </Row>
        </div>
      )}

      <div style={{ padding: '5px 30px' }}>
        <h4>备选账号</h4>
        <Row>
          {map(otherLianAccountList, account => {
            return (
              <LianAccountItem
                key={get(account, 'rowid')}
                account={account}
                resetAccountLianhongMutation={resetAccountLianhongMutation}
              />
            )
          })}
        </Row>
      </div>

      {!currentJu && (
        <div style={{ padding: '10px 30px 0 30px' }}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true)
            }}
          >
            开一局
          </Button>
        </div>
      )}
      <Modal
        title="开局"
        footer={null}
        destroyOnClose
        open={open}
        width={1000}
        onCancel={() => {
          setOpen(false)
        }}
      >
        <StartJuForm
          onClose={() => {
            setOpen(false)
          }}
        ></StartJuForm>
      </Modal>
      {currentJu && <JuItemInner ju={currentJu} accountList={accountList}></JuItemInner>}
    </div>
  )
}

export default Inner
