import { Routes, Route, Outlet, Link, NavLink } from 'react-router-dom'

import Home from './Home'
import JuItem from './JuItem'
import JuHistory from './JuHistory'

import './App.css'

export default function App() {
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="history" element={<JuHistory />} />
          <Route path="ju/:juId" element={<JuItem />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  )
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <div className="nav-container">
        <nav className="nav">
          <NavLink to="/">当前</NavLink>
          <NavLink to="/history">历史</NavLink>
        </nav>
      </div>
      <Outlet />
    </div>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
