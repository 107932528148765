import React from 'react'
import { get } from 'lodash-es'
import { useQuery } from '@tanstack/react-query'
import { getDanList } from '../../api'
import Main from './Main'

const JuItemInner = ({ ju, isHistory, accountList }) => {
  const juId = get(ju, 'rowid')

  const { data: dansData, isPending } = useQuery({
    queryKey: ['ju-item', juId, 'dans'],
    queryFn: () => getDanList({ juId }).then(res => res.data),
  })

  if (isPending || !dansData) return 'Loading...'

  const danRows = dansData.data.rows

  return <Main ju={ju} danRows={danRows} isHistory={isHistory} accountList={accountList}></Main>
}

export default JuItemInner
