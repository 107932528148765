import React, { useMemo, useState } from 'react'
import { Button, Space, Modal, message } from 'antd'
import { get, map, sortBy, flatMap, groupBy, some, isEmpty } from 'lodash-es'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { markAwardStaus, refreshJuDans, setAccountLianhong } from '../../api'
import StartJuForm from '../StartJuForm'
import StepDans from './StepDans'

const Main = ({ ju, danRows, isHistory, accountList }) => {
  const juTitle = get(ju, '6689eef14357f965f8dd64ff')
  const juNum = get(ju, '668a02540207cf7520fc99b7')
  const juId = get(ju, 'rowid')
  const [refreshLoading, setRefreshLoading] = useState(false)

  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)

  const markAwardWinMutation = useMutation({
    mutationFn: markAwardStaus,
    onSuccess: () => {
      queryClient.invalidateQueries(['ju-item', juId, 'dans'])
    },
  })

  // const cancelMarkAwardMutation = useMutation({
  //   mutationFn: cancelMarkAward,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(['ju-item', juId, 'dans'])
  //   },
  // })

  // 根据连红数排序
  const groupedDanRows = groupBy(danRows, '66964626c8525c1f88c4195a')
  const sortedKeys = sortBy(Object.keys(groupedDanRows), key => -Number(key))
  let danRowsChunks = map(sortedKeys, key => groupedDanRows[key])
  danRowsChunks = map(danRowsChunks, chunk => {
    return sortBy(chunk, item => {
      const rN = get(item, '668a0731790c0e040587af55')

      if (rN === '第一排') {
        return 0
      }

      if (rN === '第二排') {
        return 1
      }

      if (rN === '第三排') {
        return 2
      }
    })
  })

  const { firstRowDans, secondRowDans, thirdRowDans } = useMemo(() => {
    const firstRowDans = flatMap(danRowsChunks, chunk => chunk[0])
    const secondRowDans = flatMap(danRowsChunks, chunk => chunk[1])
    const thirdRowDans = flatMap(danRowsChunks, chunk => chunk[2])

    return {
      firstRowDans,
      secondRowDans,
      thirdRowDans,
    }
  }, [danRowsChunks])

  return (
    <div>
      <div style={{ display: 'flex', padding: '10px 30px', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>
          #{juNum} {juTitle}
        </h2>
        {!isHistory && (
          <div>
            <Space>
              <Button
                disabled={saveLoading}
                onClick={async () => {
                  setRefreshLoading(true)
                  await refreshJuDans({ juId })

                  setTimeout(() => {
                    setRefreshLoading(false)
                    queryClient.invalidateQueries(['ju-item', juId, 'dans'])
                  }, 10000)
                }}
                loading={refreshLoading}
              >
                刷新订单状态
              </Button>
              <Button
                loading={saveLoading}
                disabled={some(danRows, dan => {
                  return isEmpty(get(dan, '668a05b7ac7cb8ff7117787c'))
                })}
                type="primary"
                onClick={() => {
                  setSaveLoading(true)
                  setAccountLianhong({ dans: danRows, accountList }).then(() => {
                    setSaveLoading(false)
                    queryClient.invalidateQueries(['account-list'])
                    setOpen(true)
                    message.success('保存成功！')
                  })
                }}
              >
                保存并开始下一局
              </Button>
            </Space>
          </div>
        )}
      </div>
      {!isHistory && (
        <Space style={{ padding: '5px 30px' }}>
          <Button
            disabled={saveLoading}
            type="dashed"
            onClick={async () => {
              await markAwardWinMutation.mutateAsync({ dans: firstRowDans, isWin: true })
              await markAwardWinMutation.mutateAsync({ dans: [...secondRowDans, ...thirdRowDans], isWin: false })
              message.success('设置成功')
            }}
          >
            第一排中奖
          </Button>
          <Button
            disabled={saveLoading}
            type="dashed"
            onClick={async () => {
              await markAwardWinMutation.mutateAsync({ dans: secondRowDans, isWin: true })
              await markAwardWinMutation.mutateAsync({ dans: [...firstRowDans, ...thirdRowDans], isWin: false })
              message.success('设置成功')
            }}
          >
            第二排中奖
          </Button>
          <Button
            disabled={saveLoading}
            type="dashed"
            onClick={async () => {
              await markAwardWinMutation.mutateAsync({ dans: thirdRowDans, isWin: true })
              await markAwardWinMutation.mutateAsync({ dans: [...firstRowDans, ...secondRowDans], isWin: false })
              message.success('设置成功')
            }}
          >
            第三排中奖
          </Button>
          <Button
            disabled={saveLoading}
            type="dashed"
            onClick={async () => {
              await markAwardWinMutation.mutateAsync({ dans: [...firstRowDans, ...secondRowDans, ...thirdRowDans], isWin: false })
              message.success('设置成功')
            }}
          >
            全未中😂
          </Button>
          {/* <Button
            disabled={saveLoading}
            type="dashed"
            onClick={async () => {
              await cancelMarkAwardMutation.mutateAsync({ dans: danRows })
              message.success('设置成功')
            }}
          >
            取消中奖标记
          </Button> */}
        </Space>
      )}

      <div style={{ padding: '5px 30px' }}>
        {map(danRowsChunks, (danRows, index) => {
          return (
            <StepDans
              juId={juId}
              key={index}
              data={danRows}
              accountList={accountList}
              isHistory={isHistory}
              title={<>组{index + 1}</>}
            ></StepDans>
          )
        })}
      </div>

      <Modal
        title="开始下一局"
        footer={null}
        destroyOnClose
        open={open}
        width={1000}
        onCancel={() => {
          setOpen(false)
        }}
      >
        <StartJuForm
          onClose={() => {
            setOpen(false)
          }}
        ></StartJuForm>
      </Modal>
    </div>
  )
}

export default Main
