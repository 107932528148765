import { Tag, Checkbox, Table, message } from 'antd'
import { get, map, groupBy } from 'lodash-es'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { switchBlukOrder } from '../../../api'
import { useMemo } from 'react'

const StepDans = ({ juId, isHistory, title, accountList, data }) => {
  const queryClient = useQueryClient()
  const switchBlukOrderMutation = useMutation({
    mutationFn: switchBlukOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(['ju-item', juId, 'dans'])
    },
  })

  const accountsObj = useMemo(() => groupBy(accountList, 'rowid'), [accountList])

  const columns = [
    {
      title: '账号',
      dataIndex: '6689fe42ca758c8cc5ccfe54',
      key: '6689fe42ca758c8cc5ccfe54',
      width: '15%',
      render: text => {
        const d = JSON.parse(text)
        const account = d?.[0]

        return <div>{account?.name}</div>
      },
    },
    {
      title: '序号',
      dataIndex: '66924a0df52e2a006162e0aa',
      key: '66924a0df52e2a006162e0aa',
      width: '10%',
      render: text => {
        const num = get(accountsObj, `${text}.[0].669f43866175d4bd1c3ef988`)
        return num
      },
    },
    {
      title: '淘球订单id',
      dataIndex: '668f8cdfb80969563ecf38fe',
      key: '668f8cdfb80969563ecf38fe',
      width: '15%',
    },
    {
      title: '淘球订单状态',
      dataIndex: '668f8debd70d332b25de5c80',
      key: '6689fc9b0207cf7520fc91e8',
      width: '15%',
      render: text => {
        if (text === 'Ticket') {
          return '已出票'
        }

        if (text === 'Accept') {
          return '待出票'
        }

        return text
      },
    },
    {
      title: '禁用批量下单',
      dataIndex: '669918c67714267c1f048fa6',
      key: '669918c67714267c1f048fa6',
      width: '15%',
      render: (text, record) => {
        if (isHistory) {
          return text
        }
        return (
          <Checkbox
            checked={text === '1' ? true : false}
            onChange={e => {
              const targetValue = e.target.checked ? '1' : '0'

              if (targetValue !== text) {
                switchBlukOrderMutation.mutateAsync({ rowId: record.rowid, value: targetValue }).then(() => {
                  message.success('设置成功')
                })
              }
            }}
          ></Checkbox>
        )
      },
    },
    {
      title: '是否中奖',
      dataIndex: '668a05b7ac7cb8ff7117787c',
      key: '668a05b7ac7cb8ff7117787c',
      render: (text, record) => {
        if (text === '已中奖') {
          return <Tag color="red">{text}</Tag>
        }

        if (text === '未中奖') {
          return (
            <Tag color="default" bordered={false}>
              {text}
            </Tag>
          )
        }

        return '---'
      },
    },
    {
      title: '连红',
      dataIndex: '66952478f234e4ca80405f16',
      key: '66952478f234e4ca80405f16',
      width: '15%',
      render: (text, record) => {
        const lianhong = Number(text)
        const isWin = get(record, '668a05b7ac7cb8ff7117787c') === '已中奖'

        if (isWin) {
          return <Tag color="#f50">{lianhong + 1}</Tag>
        }

        if (get(record, '668a05b7ac7cb8ff7117787c') === '未中奖') {
          return <b>0</b>
        }

        return <b>{text}</b>
      },
    },
  ]

  const dataSource = map(data, item => {
    return {
      key: item.rowid,
      ...item,
    }
  })

  return (
    <div>
      <div style={{ marginTop: 20 }}>{title}</div>

      <Table
        columns={columns}
        // rowSelection={{ ...rowSelection, checkStrictly: false }}
        dataSource={dataSource}
        pagination={false}
        size="small"
      />
    </div>
  )
}

export default StepDans
