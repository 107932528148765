import { get, map } from 'lodash-es'
import { Link } from 'react-router-dom'
import { getJuListLatest } from '../api'
import { useQuery } from '@tanstack/react-query'

function Ju() {
  const { isPending: JuIsPending, data: juData } = useQuery({
    queryKey: ['ju-list'],
    queryFn: () => getJuListLatest().then(res => res.data),
  })

  if (JuIsPending) return 'Loading...'

  const juList = juData.data.rows
  const [firstJu, ...otherJuList] = juList
  return (
    <div style={{ padding: 30 }}>
      <h2>局</h2>
      {map(otherJuList, juItem => {
        const rowid = juItem.rowid
        return (
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <Link to={`/ju/${rowid}`}>
              #{get(juItem, '668a02540207cf7520fc99b7')}
              {' '}
              {get(juItem, '6689eef14357f965f8dd64ff')}
            </Link>
            <div style={{ marginLeft: 10 }}>创建时间：{get(juItem, 'ctime')}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Ju
