import React from 'react'
import { isNil } from 'lodash-es'
import { useParams } from 'react-router-dom'

import JuItemInner from '../components/JuItemInner'

import { useQuery } from '@tanstack/react-query'
import { getDanList, getJuItem } from '../api'

const JuItem = () => {
  const params = useParams()
  const { juId } = params

  const { data: JuData } = useQuery({
    queryKey: ['ju-item', juId],
    queryFn: () => getJuItem({ juId }).then(res => res.data),
  })

  const { data: dansData } = useQuery({
    queryKey: ['ju-item', juId, 'dans'],
    queryFn: () => getDanList({ juId }).then(res => res.data),
  })

  if (isNil(JuData) || isNil(dansData)) {
    return null
  }

  return <JuItemInner ju={JuData.data} isHistory></JuItemInner>
}
export default JuItem
