import axios from 'axios'
import {
  map,
  get,
  flatten,
  sortBy,
  find,
  set,
  cloneDeep,
  filter,
  takeRight,
  groupBy,
  intersectionBy,
  chunk,
  forEach,
  isEqual,
} from 'lodash-es'
import { Promise } from 'bluebird'
import moment from 'moment'

const appKey = '8751882ec0f000f2'
const sign = 'NjU5YjU1ZGZjYmE5MTQwMzZjZGZmMDk5MmQxMDcwNDdhNTNjZmZiZmEzNGM1ZGM0M2E5ODM5MDhlZjZlYTA1NQ=='

export const getAccountList = () => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/getFilterRows', {
    appKey,
    sign,
    worksheetId: 'zhanghao',
    pageSize: 500,
    pageIndex: 1,
    listType: 1,
    filters: [
      {
        controlId: '66992ba26c73406b926bb554',
        spliceType: 1,
        dataType: 36,
        value: 1,
        filterType: 6, // 不是（不等于）
      },
    ],
  })
}

export const getJuItem = ({ juId }) => {
  return axios.get('https://api.mingdao.com/v2/open/worksheet/getRowById', {
    params: {
      appKey,
      sign,
      worksheetId: 'ju',
      rowId: juId,
    },
  })
}

export const getJuListLatest = () => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/getFilterRows', {
    appKey,
    sign,
    worksheetId: 'ju',
    pageSize: 20,
    pageIndex: 1,
    listType: 1,
  })
}

export const createJu = ({ name = '七七七七' }) => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/addRow', {
    appKey,
    sign,
    worksheetId: 'ju',
    controls: [
      {
        controlId: '6689eef14357f965f8dd64ff',
        value: name,
      },
      {
        controlId: '668a12678c7c10c3a1f3f64d',
        value: '第一阶段',
      },
      {
        controlId: '668fbfe68c68dc9732280e10',
        value: '第一排',
      },
      {
        controlId: 'ownerid',
        value: 'a3326b15-f1b9-4828-ab45-00069710d9cc',
      },
    ],
    triggerWorkflow: true,
  })
}

export const getDanList = ({ viewId = '6689ef2e711a07e88b2f8384', juId }) => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/getFilterRows', {
    appKey,
    sign,
    worksheetId: 'dan',
    viewId,
    pageSize: 500,
    pageIndex: 1,
    listType: 1,
    filters: [
      {
        controlId: '6689f1e24357f965f8dd6719',
        spliceType: 1,
        dataType: 29,
        value: juId,
        filterType: 24,
      },
    ],
  })
}

export const createDans = ({ juId, accountList }) => {
  const sortedAccountList = sortBy(accountList, account => Number(get(account, '6695154ef234e4ca8040548b')))
  const accountListChunks = chunk(sortedAccountList, 3)

  const chunkRows = map(accountListChunks, (accountListChunk, chunkIndex) => {
    return map(accountListChunk, (account, index) => {
      const accountName = get(account, '66895b3a8c68dc973221e386')

      return [
        {
          controlId: '6689ef2e711a07e88b2f8381',
          value: `${accountName}__${moment().format('MM-DD/hh:mm')}`,
        },
        {
          controlId: '6689f1e24357f965f8dd6719',
          value: juId,
        },
        {
          controlId: '6689fe42ca758c8cc5ccfe54',
          value: account.rowid,
        },
        {
          controlId: '66952478f234e4ca80405f16',
          value: get(account, '6695154ef234e4ca8040548b'),
        },
        {
          controlId: '66924a0df52e2a006162e0aa',
          value: account.rowid,
        },
        {
          controlId: '66964626c8525c1f88c4195a',
          value: chunkIndex + 1,
        },
        {
          controlId: '668a0731790c0e040587af55',
          value: index === 0 ? '第一排' : index === 1 ? '第二排' : '第三排',
          valueType: 1,
        },
        {
          controlId: 'ownerid',
          value: 'a3326b15-f1b9-4828-ab45-00069710d9cc',
        },
      ]
    })
  })

  return axios.post('https://api.mingdao.com/v2/open/worksheet/addRows', {
    appKey,
    sign,
    worksheetId: 'dan',
    rows: flatten(chunkRows),
    triggerWorkflow: false,
  })
}

export const markAwardStaus = ({ dans, isWin }) => {
  const rowIds = map(dans, dan => {
    return get(dan, 'rowid')
  })

  return axios.post('https://api.mingdao.com/v2/open/worksheet/editRows', {
    appKey,
    sign,
    worksheetId: 'dan',
    rowIds,
    controls: [
      {
        controlId: '668a05b7ac7cb8ff7117787c',
        value: isWin ? '已中奖' : '未中奖',
      },
    ],
  })
}

export const cancelMarkAward = ({ dans }) => {
  const rowIds = map(dans, dan => {
    return get(dan, 'rowid')
  })

  return axios.post('https://api.mingdao.com/v2/open/worksheet/editRows', {
    appKey,
    sign,
    worksheetId: 'dan',
    rowIds,
    controls: [
      {
        controlId: '668a05b7ac7cb8ff7117787c',
        value: '',
      },
    ],
  })
}

//
const getAvailableAccountList = accountList => {
  return filter(accountList, accountItem => {
    return get(accountItem, '668faebf8c68dc973227f3f3') !== '1'
  })
}

//
const getPoolAccountList = accountList => {
  return filter(accountList, accountItem => {
    return get(accountItem, '668faebf8c68dc973227f3f3') === '1'
  })
}

//
export const getIntersectionPool = (oldAccountList, newAccountList) => {
  const oldPoolAccounts = getPoolAccountList(oldAccountList)
  const oldAvailableAccounts = getAvailableAccountList(oldAccountList)

  const newPoolAccounts = getPoolAccountList(newAccountList)
  const newAvailableAccounts = getAvailableAccountList(newAccountList)

  return [
    intersectionBy(oldPoolAccounts, newAvailableAccounts, 'rowid'),
    intersectionBy(newPoolAccounts, oldAvailableAccounts, 'rowid'),
  ]
}

//
export const getPoolChangedAccountList = (oldAccountList, newAccountList) => {
  return filter(newAccountList, accountItem => {
    const isPool = get(accountItem, '668faebf8c68dc973227f3f3')
    const oldAccountItem = find(oldAccountList, oldAccountItem => oldAccountItem.rowid === accountItem.rowid)
    const oldIsPool = get(oldAccountItem, '668faebf8c68dc973227f3f3')

    return isPool !== oldIsPool
  })
}

export const getChangedAccountList = (oldAccountList, newAccountList) => {
  return filter(newAccountList, accountItem => {
    const oldAccountItem = find(oldAccountList, oldAccountItem => oldAccountItem.rowid === accountItem.rowid)

    return !isEqual(accountItem, oldAccountItem)
  })
}

//
export const swapAccountPool = ({ accountList }) => {
  const newAccountList = cloneDeep(accountList)

  const getAvailableAccounts = () => getAvailableAccountList(newAccountList)
  const getPoolAccounts = () => getPoolAccountList(newAccountList)

  // 获取指定连红的备选账号
  const getLianPoolAccounts = lian => {
    const poolAccounts = getPoolAccounts()
    return filter(poolAccounts, account => Number(get(account, '6695154ef234e4ca8040548b')) === lian)
  }

  // 从备选中标记到可用列表中
  const removeFrmPool = targetAccounts => {
    forEach(newAccountList, accountItem => {
      if (
        get(accountItem, '668faebf8c68dc973227f3f3') === '1' &&
        find(targetAccounts, a => a.rowid === accountItem.rowid)
      ) {
        set(accountItem, '668faebf8c68dc973227f3f3', '0')
      }
    })
  }

  // 从可用列表标记为备选
  const addToPool = targetAccounts => {
    forEach(newAccountList, accountItem => {
      if (
        get(accountItem, '668faebf8c68dc973227f3f3') !== '1' &&
        find(targetAccounts, a => a.rowid === accountItem.rowid)
      ) {
        set(accountItem, '668faebf8c68dc973227f3f3', '1')
      }
    })
  }

  const swapWithPool = () => {
    const availableAccounts = getAvailableAccounts()
    const poolAccounts = getPoolAccounts()
    const accountsGroup = groupBy(availableAccounts, '6695154ef234e4ca8040548b')

    forEach(Object.keys(accountsGroup), key => {
      const currentLian = Number(key)
      const currentLianAccounts = accountsGroup[key]
      const currentLianCount = currentLianAccounts.length

      if (currentLian > 0) {
        // 新账号替换池子中的账号
        const currentLianPoolAccounts = filter(
          poolAccounts,
          account => Number(get(account, '6695154ef234e4ca8040548b')) === currentLian
        )

        const currentLianPoolCount = currentLianPoolAccounts.length

        if (currentLianCount >= currentLianPoolCount) {
          removeFrmPool(currentLianPoolAccounts)
          const toPoolAccounts = takeRight(currentLianAccounts, currentLianPoolCount)
          addToPool(toPoolAccounts)
        }
      }
    })
  }

  const checkWithPool = () => {
    const availableAccounts = getAvailableAccounts()
    const accountsGroup = groupBy(availableAccounts, '6695154ef234e4ca8040548b')

    forEach(Object.keys(accountsGroup), key => {
      const currentLian = Number(key)
      const currentLianAccounts = accountsGroup[key]
      const curretnLianCount = currentLianAccounts.length

      const mod = curretnLianCount % 3

      // 遇到 4连红只移出不移入
      if (currentLian >= 4) {
        addToPool(currentLianAccounts)
      }

      if (mod !== 0 && currentLian < 4) {
        const needCount = 3 - mod

        const targetPoolAccounts = getLianPoolAccounts(currentLian)
        const targetPoolCount = targetPoolAccounts.length

        // 找到的数量大于需要的数量
        if (needCount <= targetPoolCount) {
          // 从中取出需要的数量的账号
          const addAccounts = takeRight(targetPoolAccounts, needCount)

          // 从账号列表中标记不为池子中
          removeFrmPool(addAccounts)
        } else {
          // 从 accounts 中取出需要移除数量的账号
          const addPoolAccounts = takeRight(currentLianAccounts, mod)

          // 从账号列表中标记为在池子中
          addToPool(addPoolAccounts)
        }
      }
    })
  }

  const checkAccountCount = () => {
    const updatedAvailableAccounts = getAvailableAccounts()
    const updatedPoolAccounts = getPoolAccounts()

    console.log('updatedAvailableAccounts', updatedAvailableAccounts)
    const diffNum = 81 - updatedAvailableAccounts.length
    if (diffNum > 0) {
      const alternativeAccountList = takeRight(
        filter(updatedPoolAccounts, a => get(a, '6695154ef234e4ca8040548b') === '0'),
        diffNum
      )
      removeFrmPool(alternativeAccountList)
    }

    if (diffNum < 0) {
      const alternativeAccountList = takeRight(
        filter(updatedAvailableAccounts, a => get(a, '6695154ef234e4ca8040548b') === '0'),
        Math.abs(diffNum)
      )
      addToPool(alternativeAccountList)
    }
  }
  //
  swapWithPool()
  //
  checkWithPool()
  //
  checkAccountCount()

  const [intersectionPools, intersectionAvailables] = getIntersectionPool(accountList, newAccountList)
  const poolChangedAccountList = getPoolChangedAccountList(accountList, newAccountList)

  return { intersectionPools, intersectionAvailables, newAccountList, poolChangedAccountList }
}

export const setAccountSwapPool = newAccountList => {
  return Promise.map(
    newAccountList,
    accountItem => {
      return axios.post('https://api.mingdao.com/v2/open/worksheet/editRow', {
        appKey,
        sign,
        worksheetId: 'zhanghao',
        rowId: accountItem.rowid,
        controls: [
          {
            controlId: '668faebf8c68dc973227f3f3',
            value: get(accountItem, '668faebf8c68dc973227f3f3'),
          },
        ],
      })
    },
    { concurrency: 5 }
  )
}

export const setAccountLianhong = ({ dans, accountList }) => {
  const newAccountList = cloneDeep(accountList)

  // 设置中奖账号连红 + 1
  forEach(dans, dan => {
    const lianhong = Number(get(dan, '66952478f234e4ca80405f16'))
    const isWin = get(dan, '668a05b7ac7cb8ff7117787c') === '已中奖'
    const accountRowid = get(dan, '66924a0df52e2a006162e0aa')

    const account = find(newAccountList, account => account.rowid === accountRowid)
    set(account, '6695154ef234e4ca8040548b', isWin ? String(lianhong + 1) : '0')
  })

  const changedAccountList = getChangedAccountList(accountList, newAccountList)

  console.log('changedAccountList', changedAccountList)
  return Promise.map(
    changedAccountList,
    accountItem => {
      return axios.post('https://api.mingdao.com/v2/open/worksheet/editRow', {
        appKey,
        sign,
        worksheetId: 'zhanghao',
        rowId: accountItem.rowid,
        controls: [
          {
            controlId: '6695154ef234e4ca8040548b',
            value: get(accountItem, '6695154ef234e4ca8040548b'),
          },
        ],
      })
    },
    { concurrency: 5 }
  )
}

export const refreshJuDans = ({ juId }) => {
  axios.post('https://api.mingdao.com/workflow/hooks/NjY4ZTJlYmMxYjI2ZmI0YTA0NzZmOWFi', {
    juId: juId,
  })
}

export const resetAccountLianhong = ({ account }) => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/editRow', {
    appKey,
    sign,
    worksheetId: 'zhanghao',
    rowId: account.rowid,
    controls: [
      {
        controlId: '6695154ef234e4ca8040548b',
        value: 0,
      },
    ],
  })
}

export const switchBlukOrder = ({ rowId, value }) => {
  return axios.post('https://api.mingdao.com/v2/open/worksheet/editRow', {
    appKey,
    sign,
    worksheetId: 'dan',
    rowId,
    controls: [
      {
        controlId: '669918c67714267c1f048fa6',
        value: value,
      },
    ],
  })
}
